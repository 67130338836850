import React, { useEffect, useRef } from "react";
import "./HomeSection.css";

function HomeSection() {
  const typingAnimationRef = useRef(null);
  const animationTimeoutRef = useRef(null);

  useEffect(() => {
    const typingAnimationElement = typingAnimationRef.current;
    const typingText = "A Full Stack Software Developer";

    if (!typingAnimationElement) return;

    function playTypingAnimation() {
      typingAnimationElement.textContent = ""; // Clear previous text

      let index = 0;
      function typeCharacter() {
        if (index < typingText.length) {
          typingAnimationElement.textContent += typingText[index];
          index++;
          animationTimeoutRef.current = setTimeout(typeCharacter, 50); // Adjust speed here
        } else {
          // Once typing is complete, clear text and restart after delay
          setTimeout(() => {
            typingAnimationElement.textContent = "";
            playTypingAnimation(); // Restart the animation
          }, 1000); // 1 second delay before restart
        }
      }

      typeCharacter();
    }

    playTypingAnimation();

    return () => {
      if (animationTimeoutRef.current) {
        clearTimeout(animationTimeoutRef.current);
      }
    };
  }, []);

  return (
    <section id="home-section" className="hero">
      <div className="container">
        <div className="row justify-content-center align-items-center text-center">
          <div className="col-md-8">
            <div className="text">
              <span className="subheading">Hello!</span>
              <h1 className="mb-4 mt-3">
                I'm <span>Nitesh Giri</span>
              </h1>
              <span
                id="typing-animation"
                className="typing-text"
                ref={typingAnimationRef}
              ></span>
              <br />
              <br />
              <p>
                <a
                  href="https://www.linkedin.com/in/nitesh-giri-539124204/"
                  className="btn btn-primary py-3 px-4"
                >
                  LinkedIn
                </a>
                <a href="#" className="btn btn-black py-3 px-4">
                  My Work
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomeSection;
