import React from "react";
import "./AboutSection.css";

function AboutSection() {
  return (
    <section
      className="ftco-about img ftco-section ftco-no-pb"
      id="about-section"
    >
      <div className="container">
        <div className="row">
          {/* About Me - Left Side */}
          <div className="col-md-12 col-lg-6 d-flex flex-column justify-content-center align-items-start text-light">
            <div className="heading-section mb-4">
              <h1 className="big">About Me</h1>
              {/* <h2 className="mb-4">Who I Am</h2> */}
            </div>
            <p>
              With 2 years of hands-on experience in software development, I
              have built a strong foundation in both front-end and back-end
              technologies. My expertise spans developing scalable applications
              with React.js and Node.js, and I have significant experience
              working on multi-tenant applications. My work with GenAI
              applications using open-source models has sharpened my skills in
              creating impactful solutions. I am dedicated to solving complex
              problems and delivering high-quality, user-friendly applications.
            </p>
            <ul className="about-info">
              <li>
                <span className="title-s">Profile:</span> Full Stack Software
                Developer
              </li>
              <li>
                <span className="title-s">Domain:</span> Healthcare, Education,
                E-commerce
              </li>
              <li>
                <span className="title-s">Education:</span> Bachelor of
                Technology
              </li>
              <li>
                <span className="title-s">Languages:</span> English, Hindi,
                Marathi
              </li>
              <li>
                <span className="title-s">Frontend Skills:</span> React.js,
                Bootstrap
              </li>
              <li>
                <span className="title-s">Backend Skills:</span> Node.js,
                Python, Java, Spring Boot
              </li>
              <li>
                <span className="title-s">Frameworks:</span> Flask, Express.js
              </li>
              <li>
                <span className="title-s">Databases:</span> MySQL
              </li>
              <li>
                <span className="title-s">Tools:</span> Postman, Cloud, Git,
                JIRA, Azure Services, DigitalOcean
              </li>
              <li>
                <span className="title-s">Interests:</span> Traveling, Travel
                Photography, Teaching
              </li>
            </ul>
          </div>

          {/* Skills - Right Side */}
          <div className="col-md-12 col-lg-6 d-flex flex-column justify-content-center text-light">
            <div className="skill-mf">
              <p className="title-s">Skills</p>
              <div className="skill">
                <span>React JS</span>
                <span className="pull-right">90%</span>
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: "90%" }}
                    aria-valuenow="90"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <div className="skill">
                <span>Node Js</span>
                <span className="pull-right">95%</span>
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: "95%" }}
                    aria-valuenow="95"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <div className="skill">
                <span>MySQL</span>
                <span className="pull-right">90%</span>
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: "90%" }}
                    aria-valuenow="90"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <div className="skill">
                <span>PYTHON</span>
                <span className="pull-right">85%</span>
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: "85%" }}
                    aria-valuenow="85"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <div className="skill">
                <span>JAVA</span>
                <span className="pull-right">85%</span>
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: "85%" }}
                    aria-valuenow="85"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <div className="skill">
                <span>Spring Boot / JPA</span>
                <span className="pull-right">85%</span>
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: "85%" }}
                    aria-valuenow="85"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <div className="skill">
                <span>Data Visualization</span>
                <span className="pull-right">90%</span>
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: "90%" }}
                    aria-valuenow="90"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>

              <div className="skill">
                <span>Machine Learning</span>
                <span className="pull-right">80%</span>
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: "80%" }}
                    aria-valuenow="80"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutSection;
