import React from "react";
import "./ContactSection.css"; // Import the CSS file for styling

const ContactSection = () => {
  return (
    <section className="contact-section" id="contact-section">
      <div className="container">
        <div className="row justify-content-center mb-5 pb-3">
          <div className="col-md-7 text-center">
            <h1 className="big big-2">Contact Me</h1>
            {/* <h2 className="mb-4">Contact Me</h2> */}
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-md-6 col-lg-3 mb-4">
            <div className="contact-box text-center p-4">
              <div className="icon mb-3">
                <span className="icon-map-signs"></span>
              </div>
              <h3 className="mb-4">Address</h3>
              <p>Pune Maharashtra, India</p>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 mb-4">
            <div className="contact-box text-center p-4">
              <div className="icon mb-3">
                <span className="icon-phone2"></span>
              </div>
              <h3 className="mb-4">Contact Number</h3>
              <p>
                <a href="tel://0101010101">+91 784 1039 834</a>
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 mb-4">
            <div className="contact-box text-center p-4">
              <div className="icon mb-3">
                <span className="icon-paper-plane"></span>
              </div>
              <h3 className="mb-4">Email Address</h3>
              <p>
                <a href="mailto:nitugiri02@gmail.com">nitugiri02@gmail.com</a>
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 mb-4">
            <div className="contact-box text-center p-4">
              <div className="icon mb-3">
                <span className="icon-globe"></span>
              </div>
              <h3 className="mb-4">Download Resume</h3>
              <p>
                <a href="#">Download Resume</a>
              </p>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mb-5">
          <div className="col-md-7 text-center">
            <h2>
              Have a <span>Question?</span>{" "}
              <a href="#" className="btn btn-primary py-3 px-5">
                Click Here
              </a>
            </h2>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
