import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <footer className="ftco-footer ftco-bg-dark ftco-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <p className="footer-text">
              &copy; {new Date().getFullYear()} Nitesh Giri. All rights
              reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
