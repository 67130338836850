import React, { useState, useEffect } from "react";
import "./Navbar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

function Navbar() {
  const [activeLink, setActiveLink] = useState("home");
  const [isSticky, setIsSticky] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    // Intersection Observer Setup
    const sections = document.querySelectorAll("section");
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const id = entry.target.getAttribute("id");
            setActiveLink(id.replace("-section", ""));
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.4, // Trigger when 70% of the section is in view
      }
    );

    sections.forEach((section) => {
      observer.observe(section);
    });

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      sections.forEach((section) => {
        observer.unobserve(section);
      });
    };
  }, []);

  return (
    <nav
      className={`navbar navbar-expand-lg navbar-dark bg-dark ${
        isSticky ? "fixed-top" : ""
      }`}
      id="navbar"
    >
      <div className="container">
        <a className="navbar-brand" href="#home-section">
          Nitesh Giri
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <a
                href="#home-section"
                className={`nav-link ${activeLink === "home" ? "active" : ""}`}
              >
                Home
              </a>
            </li>
            <li className="nav-item">
              <a
                href="#about-section"
                className={`nav-link ${activeLink === "about" ? "active" : ""}`}
              >
                About
              </a>
            </li>
            <li className="nav-item">
              <a
                href="#resume-section"
                className={`nav-link ${
                  activeLink === "resume" ? "active" : ""
                }`}
              >
                Resume
              </a>
            </li>
            <li className="nav-item">
              <a
                href="#project-section"
                className={`nav-link ${
                  activeLink === "projects" ? "active" : ""
                }`}
              >
                Projects
              </a>
            </li>
            <li className="nav-item">
              <a
                href="#contact-section"
                className={`nav-link ${
                  activeLink === "contact" ? "active" : ""
                }`}
              >
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
