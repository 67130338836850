import React from "react";
import "./ProjectSection.css"; // Import the CSS file for styling
import proj1 from "../images/proj_1.jpg";
import proj2 from "../images/proj_2.jpg";
import proj3 from "../images/proj_3.jpg";
import proj4 from "../images/proj_4.jpg";
import proj5 from "../images/proj_5.jpg";

const projects = [
  {
    img: proj1,
    link: "#",
    title: "Digital Music Store Data Analysis using SQL",
    description:
      "Analyzed music store data using advanced SQL queries to identify gaps and increase business growth.",
  },
  {
    img: proj2,
    link: "#",
    title: "Data Analysis using Python Project for Beginners",
    description:
      "Performed exploratory data analysis on Diwali sales data using Python to improve customer experience.",
  },
  {
    img: proj3,
    link: "#",
    title: "Power BI Sales Dashboard Project for Beginners",
    description:
      "Designed a Power BI dashboard for Madhav Store to track and analyze online sales data across India.",
  },
  {
    img: proj4,
    link: "#",
    title: "Sales Forecast - Time Series Forecasting",
    description:
      "Used multiple machine learning models to forecast sales (retail store) and performed time series analysis.",
  },
  {
    img: proj5,
    link: "#",
    title: "Customer Segmentation using Clustering Model",
    description:
      "Developed an ML model to provide various recommendations of financial products & services to target customer groups.",
  },
];

const ProjectSection = () => {
  return (
    <section className="ftco-section" id="project-section">
      <div className="container">
        <div className="row justify-content-center mb-5 pb-5">
          <div className="col-md-7 heading-section text-center ftco-animate">
            <h1 className="big big-2">Projects</h1>

            <p>
              Updating Soon
              {/* Below are the sample Data Analytics projects on SQL, Python, Power
              BI & ML. */}
            </p>
          </div>
        </div>

        {/* <div className="row">
          {projects.map((project, index) => (
            <div key={index} className="col-md-4 d-flex ftco-animate">
              <div className="card">
                <a
                  href={project.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div
                    className="card-image"
                    style={{ backgroundImage: `url(${project.img})` }}
                  ></div>
                </a>
                <div className="card-body">
                  <h3 className="card-title">
                    <a
                      href={project.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {project.title}
                    </a>
                  </h3>
                  <p className="card-text">{project.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div> */}
      </div>
    </section>
  );
};

export default ProjectSection;
