import React from "react";
import "./ResumeSection.css"; // Import the CSS file for styling

const ResumeSection = () => {
  return (
    <section className="ftco-section ftco-no-pb" id="resume-section">
      <div className="container">
        <div className="row justify-content-center pb-5">
          <div className="col-md-10 heading-section text-center ftco-animate">
            <h1 className="big big-2">Resume</h1>
            <p className="intro-text">
              Full Stack Software Developer with 2 years of hands-on experience
              in software development, adept at both front-end and back-end
              technologies. Skilled in creating scalable applications with
              React.js and Node.js, and experienced in developing multi-tenant
              applications. Proficient in GenAI applications using open-source
              models, with a strong focus on solving complex problems and
              delivering high-quality, user-friendly solutions. Dedicated to
              advancing in healthcare, education, and e-commerce domains.
            </p>
          </div>
        </div>

        <div className="row">
          <h1 className="big-4">Experience</h1>
          <div className="underline"></div>
        </div>
        <br />

        <div className="row">
          <div className="col-md-6">
            <div className="resume-wrap ftco-animate">
              <span className="date">May 2023 - Present</span>
              <h2>Full Stack Web Developer</h2>
              <span className="position">Silfra Technologies</span>
              <p className="mt-4">
                Currently working as a Full Stack Web Developer at Silfra
                Technologies. Key responsibilities include:
                <ul>
                  <li>
                    Developing RESTful backend APIs using Node.js and Python
                    (Flask).
                  </li>
                  <li>Building front-end applications with React.js.</li>
                  <li>Working with and fine-tuning open-source models.</li>
                  <li>
                    Implementing JWT token security for backend API
                    applications.
                  </li>
                  <li>
                    Planning and managing project timelines to ensure timely
                    delivery.
                  </li>
                  <li>
                    Implementing designs and integrating user interfaces with
                    backend services.
                  </li>
                  <li>
                    Deploying projects on Linux servers and managing server
                    configurations.
                  </li>
                  <li>
                    Keeping code up-to-date on Bitbucket and managing version
                    control.
                  </li>
                  <li>Tracking and resolving issues using Jira.</li>
                </ul>
              </p>
            </div>
          </div>

          <div className="col-md-6">
            <div className="resume-wrap ftco-animate">
              <span className="date">Feb 2022 - Dec 2022</span>
              <h2>Backend Developer & Intern</h2>
              <span className="position">Hefshine Software Pvt Ltd</span>
              <p className="mt-4">
                Worked as a Backend Developer and Intern at Hefshine Software
                Pvt Ltd. Key responsibilities and achievements include:
                <ul>
                  <li>
                    Learned and applied Java, Spring Boot, and Hibernate in
                    real-world projects.
                  </li>
                  <li>
                    Developed backend APIs using Spring Boot, ensuring robust
                    and scalable solutions.
                  </li>
                  <li>
                    Worked on database management and integration with backend
                    services.
                  </li>
                  <li>
                    Engaged in creating and testing RESTful APIs to support
                    various applications.
                  </li>
                  <li>
                    Collaborated with front-end developers to ensure seamless
                    integration of backend services.
                  </li>
                  <li>
                    Worked as a freelance developer during the same period,
                    taking on various projects related to backend API
                    development.
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>

        <br />
        <br />

        <div className="row">
          <h1 className="big-4">Education</h1>
          <div className="underline"></div>
        </div>
        <br />

        <div className="row align-items-stretch">
          <div className="col-md-6">
            <div className="resume-wrap ftco-animate">
              <span className="date">2017-2021</span>
              <span className="position">
                Shri Guru Gobind Singhji Institute of Engineering and Technology
                (SGGSIET), Nanded
              </span>
              <h2>Bachelor of Technology</h2>
              <span className="date">
                Swami Ramanand Teerth Marathwada University
              </span>
            </div>
          </div>

          <div className="col-md-6">
            <div className="resume-wrap ftco-animate">
              <span className="date">2015-2017</span>
              <span className="position">
                Shivaji Vidyalay, Cidco New Nanded Maharashtra - 431003
              </span>
              <h2>Higher Secondary School</h2>
              <span></span>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mt-5">
          <div className="col-md-6 text-center ftco-animate">
            <p>
              <a href="#" className="btn btn-primary py-4 px-5">
                Download CV
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResumeSection;
